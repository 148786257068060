<template>
  <div class="animated fadeIn">
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1 class="h3">{{ $t('message.demo_success_title') }}</h1>

            <img class="img-fluid m-5" height="200" src="/img/ok.png" width="200"/>

          </div>
          <b-row>
            <b-col md="6" sm="12">
              <router-link :to="{name:'categories'}">
                <b-button block class="bg-theme-secondary">
                  {{ $t('message.back_to_shopping') }}
                </b-button>
              </router-link>
            </b-col>
            <b-col md="6" sm="12">
              <b-button block
                        class="bg-theme-secondary"
                        @click="externalLink(links.control_panel)">
                {{ $t('message.go_to_backend') }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ApiResolver from '../components/common/ApiResolver';
import Api from '../components/common/Api';

import {mapState, mapGetters} from "vuex";

export default {
  name: 'demoSuccess',
  components: {},
  props: [
    'query'
  ],
  data() {
    return {
      links: {
        control_panel: process.env.VUE_APP_ADMIN
      },
    };
  },
  computed: {
    ...mapState({
      cart: state => state.cart,
      currentUser: state => state.user.currentUser
    }),
  },
  methods: {
    externalLink(link) {
      window.location.href = link;
    },
  },
  mounted() {

  },
};
</script>

<style scoped>
.hasError label {
  color: red;
}

.text-white {
  text-decoration: none;
}

.error {
  color: red
}
</style>


